<div class="container-fluid">

    <div class="row justify-content-center">
        <div class="col-lg-5">
            <div class="text-center mb-5">
                <h4>باقات الاسعار</h4>
                <p class="text-muted mb-4">يجب الاشتراك في احدي الباقات للاستفادة من مميزات البرنامج</p>
            </div>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-lg-9">
            <div class="row">
                <div class="col-xl-4 offset-xl-4">
                    <div class="card pricing-box text-center">
                        <div class="card-body p-4">
                            <div>
                                <div class="mt-3">
                                    <h5 class="mb-1"> سنة</h5>
                                    <p class="text-muted">لجميع الخدمات</p>
                                </div>
                            </div>
                            <div class="py-4">
                                <h3><sup><small></small></sup> ٨٠ <span class="font-size-13 text-muted"> ريال
                                        سعودي</span></h3>
                            </div>
                            <div class="text-center plan-btn my-2">
                                <button (click)="pay(80)" class="btn btn-primary waves-effect waves-light">شراء</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-4">
                    <div class="card pricing-box text-center">
                        <div class="card-body p-4">
                            <div>
                                <div class="mt-3">
                                    <h5 class="mb-1">سنتين</h5>
                                    <p class="text-muted">لجميع الخدمات</p>
                                </div>
                            </div>
                            <div class="py-4">
                                <h3><sup><small></small></sup> ١٠٠ <span class="font-size-13 text-muted"> ريال
                                        سعودي</span></h3>
                            </div>
                            <div class="text-center plan-btn my-2">
                                <button (click)="pay(100)"
                                    class="btn btn-primary waves-effect waves-light">شراء</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="card pricing-box text-center">
                        <div class="card-body p-4">
                            <div>
                                <div class="mt-3">
                                    <h5 class="mb-1">ثلاث سنوات</h5>
                                    <p class="text-muted">لجميع الخدمات</p>
                                </div>
                            </div>
                            <div class="py-4">
                                <h3><sup><small></small></sup> ١٥٠ <span class="font-size-13 text-muted"> ريال
                                        سعودي</span></h3>
                            </div>
                            <div class="text-center plan-btn my-2">
                                <button (click)="pay(150)"
                                    class="btn btn-primary waves-effect waves-light">شراء</button>
                            </div>
                        </div>
                    </div>
                </div> -->

            </div>
            <!-- end row -->
        </div>
    </div>