import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/services/auth.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  constructor(private auth: AuthService) { }

  ngOnInit(): void {
  }

  pay(price: Number) {
    window.open('https://taw-edu.com/اشتراك-لمدة-سنة-في-برنامج-توثيق/p1717386157', '_blank');
    // this.auth.payment({"price":price}).subscribe( res => {
    //   console.log(res);
    //   res = res.data;
    //   if(res && res.redirect_url){
    //     let redirectUrl = res.redirect_url;
    //     window.open(redirectUrl, "_self");
    //   }
    // })
  }

}
